import _cli from "./lib/cli.js";
import _process from "process";
var exports = {};
var module = {
  exports: exports
};
var process = _process;
if (undefined === module) {
  _cli(process);
} else {
  throw new Error("The programmatic API was removed in npm v8.0.0");
}
export default module.exports;